import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import ProjectThumbnail from './ProjectThumbnail';

import projectThumbnailsStyles from './project-thumbnails.module.css';

const ProjectThumbnails = ( { className, projects } ) => {
	const mapProjects = () => projects.map( ( project, i ) => {
		return (
			<Link
				key={ i }
				to={ `/${ project.slug }` }
				className={ projectThumbnailsStyles.thumbnail }
			>
				<ProjectThumbnail
					thumbnail={ project.thumbnail }
					title={ project.title }
					year={ project.year }
				/>
			</Link>
		);
	} );

	return (
		<div className={ `${ className } ${ projectThumbnailsStyles.thumbnails }` }>
			{ mapProjects() }
		</div>
	);
};

ProjectThumbnails.propTypes = {
	className: PropTypes.string,
	projects: PropTypes.arrayOf( PropTypes.object ).isRequired
};

export default ProjectThumbnails;
