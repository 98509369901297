import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';

import projectThumbnailsStyles from './project-thumbnails.module.css';

const ProjectThumbnail = ( { thumbnail, title, year } ) => {
	return (
		<span className={ projectThumbnailsStyles.container }>
			<Img
				alt={ `Project thumbnail for ${ title }`}
				backgroundColor="#dedede"
				className={ projectThumbnailsStyles.thumbnailImage }
				fluid={ thumbnail.childImageSharp.fluid }
				placeholderStyle={ { opacity: 0 } }
			/>
			<span className={ projectThumbnailsStyles.title }>
				{ title }&nbsp;({ year })
			</span>
		</span>
	);
};

ProjectThumbnail.propTypes = {
	thumbnail: PropTypes.object.isRequired,
	title: PropTypes.string.isRequired,
	year: PropTypes.string.isRequired
};

export default ProjectThumbnail;
