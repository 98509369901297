import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import { groupByProp } from '../../utils';

import Layout from '../../components/Layout';
import SEO from '../../components/SEO';
import ProjectThumbnails from '../../components/ProjectThumbnails';

import projectsStyles from './projects.module.css';

const Projects = ( { data } ) => {
	const { nodes: projectNodes } = data.projects;
	const { nodes: categoryNodes } = data.categories;

	const projects = projectNodes.map( node => {
		const { childMarkdownRemark: { frontmatter: { title, year, category, thumbnail } } } = node;

		return {
			slug: node.relativeDirectory,
			title,
			year,
			category,
			thumbnail
		};
	} );
	const groupedProjects = groupByProp( projects, 'category' );

	const sortedProjects = Object.entries( groupedProjects )
		.reduce( ( acc, [ key, value ] ) => {
			const sortedValue = value.sort( ( a, b ) => parseInt( b.year ) - parseInt( a.year ) );

			return { ...acc, [ key ]: sortedValue };
		}, {} );

	console.log( sortedProjects );

	const categories = categoryNodes.map( node => {
		const text = projects.find( ( { slug } ) => slug.includes( node.name ) ).category;

		return { id: node.name, text, to: `/projects#${ node.name }` };
	} );

	const mapProjects = () => categories.map( ( category, i ) => (
		<div key={ i } className={ projectsStyles.category }>
			<header className={ projectsStyles.header }>
				<h1 id={ category.id } className={ projectsStyles.title }>{ category.text }</h1>
			</header>
			<ProjectThumbnails projects={ sortedProjects[ category.text ] } />
		</div>
	) );

	return (
		<Layout
			links={ categories }
		>
			<SEO title="Home" />
			<div className="wrapper page-content">
				<div className="right-page-column">
					{ mapProjects() }
				</div>
			</div>
		</Layout>
	);
};

Projects.propTypes = { data: PropTypes.object.isRequired };

export default Projects;

export const pageQuery = graphql`
	query {
		projects: allFile(filter: {relativeDirectory: {regex: "/^projects\/.*\/.*/"}, extension: {eq: "md"}}) {
			nodes {
				relativeDirectory
				childMarkdownRemark {
					frontmatter {
						title
						year
						category
						thumbnail {
							childImageSharp {
								fluid(maxWidth: 500, quality: 50) {
									...GatsbyImageSharpFluid
								}
							}
						}
					}
				}
			}
		}
		categories: allDirectory(filter: {relativeDirectory: {eq: "projects"}}) {
			nodes {
				name
			}
		}
	}
`;
