const groupByProp = function( arr, prop ) {
	// create a aset of all of the unique values for the given property
	const uniquePropValues = [ ...new Set( arr.map( item => item[ prop ] ) ) ];

	let sortedArr = [];

	// loop through array of unique property values
	uniquePropValues.forEach( uniquePropValue => {
		// create an array of all of the items in the original array that have a matching value for the given property
		const items = arr.filter( item => item[ prop ] === uniquePropValue );

		// add the newly created array to the accumulator object with a key of the unique property value
		sortedArr = { ...sortedArr, [ uniquePropValue ]: items };
	} );

	return sortedArr;
};

export default groupByProp;
